
import { css } from '@emotion/react'
import React from 'react';

import { CardElevation } from '@findep/mf-landings-core'
import { Typography, Tooltip, Grid } from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { urlBFF } from '../../services/LandingV4/Instance'
import { useTheme } from "@findep/microfronts-core"
import { InfoOutlined } from '@material-ui/icons'

const Documents = (props) => {
    const { palette } = useTheme()

    const danger = css`
        color: ${palette.danger.dark};
    `

    const success = css`
        color: ${palette.success.dark};
    `
    const tooltipCss = css`
        justify-self: flex-end;
        color: ${palette.action.disabled};
    `

    return (
        <CardElevation fullWidth>
            <Typography css={props.textCss}>
                {props.message}
            </Typography>
            {props.files.map((row) => (
                <div style={{ display: 'flex' }} >
                    {row.result === "ERROR"
                        ?
                        <Grid container>
                            <Grid item xs={1}>
                                <HighlightOffTwoToneIcon css={danger} />
                            </Grid>
                            <Grid item xs={10}>
                                <a css={danger} target="_blank" rel="noreferrer" href={`${urlBFF}/cav/documents?documentUrl=${encodeURI(row.urlBase)}&CreditApplicationID=${props.flujo.creditApplicationId}`}>
                                    {row.label}
                                </a>
                            </Grid>
                            <Grid item xs={1}>
                                {
                                    row.comentarioEjecutivo !== "" &&

                                    < Tooltip title={row.comentarioEjecutivo} placement="right" arrow>
                                        <InfoOutlined css={tooltipCss} />
                                    </Tooltip>
                                }
                                {
                                    (row.comentarioEjecutivo === "" && row.descripcionResolucion !== "") &&
                                    < Tooltip title={row.descripcionResolucion} placement="right" arrow>
                                        <InfoOutlined css={tooltipCss} />
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={1}>
                                <CheckCircleIcon css={success} />
                            </Grid>
                            <Grid item xs={10}>
                                <a css={success} target="_blank" rel="noreferrer" href={`${urlBFF}/cav/documents?documentUrl=${encodeURI(row.urlBase)}&CreditApplicationID=${props.flujo.creditApplicationId}`}>
                                    {row.subEtiqueta}
                                </a>
                            </Grid>
                            <Grid item xs={1}>
                                {
                                    row.comentarioEjecutivo !== "" &&

                                    < Tooltip title={row.comentarioEjecutivo} placement="right" arrow>
                                        <InfoOutlined css={tooltipCss} />
                                    </Tooltip>
                                }
                                {
                                    (row.comentarioEjecutivo === "" && row.descripcionResolucion !== "") &&
                                    < Tooltip title={row.descripcionResolucion} placement="right" arrow>
                                        <InfoOutlined css={tooltipCss} />
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    }
                </div>
            ))
            }
        </CardElevation >
    )
}

export default Documents;